import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { Colors, FontWeight } from 'src/constants/theme'
import { RedirectUrls } from '../../types/types'
import BaseButton from '../Buttons/BaseButton'
import FlexBox from '../FlexBox'
import { StyledSectionTitleColor } from './styled'

const StyledSectionTitle = styled.div`
  font-size: 18px;
  font-weight: ${FontWeight.semiBold};
  color: ${Colors.black400};
  text-align: center;
`

export default function AlreadyLoggedIn() {
  const router = useRouter()
  const url = router.query['redirect']
  const redirect = url && typeof url === 'string' ? url : undefined

  return (
    <FlexBox fullWidth direction="column" center gap={2}>
      <i className={`si si-lock text-3xl text-black-400`} />
      <StyledSectionTitle>
        Ya está conectado a{' '}
        <StyledSectionTitleColor>SpotMe</StyledSectionTitleColor>
      </StyledSectionTitle>

      <BaseButton
        variant="contained"
        width={150}
        rounded
        href={redirect ?? RedirectUrls.Home}
      >
        Continuar
      </BaseButton>
    </FlexBox>
  )
}
