import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import React, { useEffect, useRef, useState } from 'react'

const StyledInput = styled(TextField)({
  '& input[type="number"]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      '-webkit-appearance': 'none'
    },
  '& input': {
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: '6px',
    width: '30px'
  }
})

const CodeInput: React.FC<{
  onComplete: (code: string) => void
}> = ({ onComplete }) => {
  const [codes, setCodes] = useState<string[]>(['', '', '', '', '', ''])
  const [isCodeComplete, setIsCodeComplete] = useState<boolean>(false)

  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null))

  useEffect(() => {
    const code = codes.join('')
    if (code.length === 6) {
      setIsCodeComplete(true)
      onComplete(code)
    } else {
      setIsCodeComplete(false)
    }
  }, [codes, onComplete])

  useEffect(() => {
    const code = codes.join('')
    onComplete(code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeComplete, onComplete])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newCode = event.target.value.replace(/[^0-9]/g, '').slice(0, 1)
    const newCodes = [...codes]
    newCodes[index] = newCode
    setCodes(newCodes)

    if (newCode && index < 5) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    if (event.key === 'Backspace' && !codes[index] && index > 0) {
      event.preventDefault()
      const newCodes = [...codes]
      newCodes[index - 1] = ''
      setCodes(newCodes)
      inputRefs.current[index - 1]?.focus()
    }
  }

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      {Array.from({ length: 6 }, (_, index) => (
        <Grid item key={index}>
          <StyledInput
            variant="outlined"
            size="small"
            type="number"
            inputProps={{ maxLength: 1 }}
            value={codes[index] || ''}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            autoFocus={index === 0}
            inputRef={(el) => (inputRefs.current[index] = el)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default CodeInput
