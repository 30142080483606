import { gql, useLazyQuery } from '@apollo/client'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { Spacing } from '../../../constants/theme'
import { useDebounce } from '../../../hooks/useDebounce'
import {
  IQuery,
  IQueryEmailBelongsToUserArgs,
  IRegisterUserInput
} from '../../../types/graphql'
import { validEmail } from '../../../utils'
import BaseButton from '../../Buttons/BaseButton'
import FlexBox from '../../FlexBox'
import {
  IEmailState,
  LoginFooter,
  LoginHeader,
  LoginType,
  LoginTypeEvent
} from '../common'
import { StyledContainer, StyledRecaptcha } from '../styled'

const EMAIL_BELONGS_TO_USER = gql`
  query EmailBelongsToUser($email: String!) {
    emailBelongsToUser(email: $email)
  }
`

type IProps = {
  isModal: boolean
  initialEmail: string | undefined
  setEmailState: (state: IEmailState) => void
  loginType: LoginType
  setLoginType: (type: LoginType) => void
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export default function LoginEmailPrompt({
  isModal,
  initialEmail,
  setEmailState,
  loginType,
  setLoginType,
  saveUserInfo
}: IProps) {
  const [email, setEmail] = useState<string>(initialEmail ?? '')
  const [hasError, setHasError] = useState<boolean>(false)
  const [continueEmail, setContinueEmail] = useState<boolean>(false)
  const debouncedValue = useDebounce(email, 700)
  const [emailExists, setEmailsExists] = useState<boolean>(false)

  const logger = useLogger()
  const [emailBelongsToUser, { loading }] = useLazyQuery<
    Pick<IQuery, 'emailBelongsToUser'>,
    IQueryEmailBelongsToUserArgs
  >(EMAIL_BELONGS_TO_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setEmailsExists(data.emailBelongsToUser)
    },
    onError: (error) => {
      logger.error('Error checking if email belongs to user', error)
    }
  })

  useEffect(() => {
    if (validEmail(email)) {
      emailBelongsToUser({ variables: { email } })
    }
  }, [email, emailBelongsToUser])

  useEffect(() => {
    if (validEmail(debouncedValue) && !loading && continueEmail) {
      setEmailState({
        email: debouncedValue,
        emailExists: emailExists
      })
    }
  }, [continueEmail, debouncedValue, emailExists, loading, setEmailState])

  const handleOnContinueEmail = () => {
    if (validEmail(email)) {
      setContinueEmail(true)
      setHasError(false)
    } else {
      setHasError(true)
    }
  }

  return (
    <StyledContainer>
      <LoginHeader isModal={isModal} />

      <FlexBox fullWidth direction="column" pt={Spacing.medium}>
        <TextField
          required
          id="email"
          name="email"
          label="Correo"
          type="email"
          fullWidth
          autoComplete="email"
          value={email}
          onChange={(event) => {
            setHasError(false)
            setEmail(event.target.value)
          }}
          error={hasError}
          size="medium"
        />
      </FlexBox>

      <FlexBox fullWidth center py={Spacing.small}>
        <StyledRecaptcha id="recaptcha-container" />
      </FlexBox>

      <FlexBox fullWidth direction="column" py={Spacing.xSmall} center>
        <BaseButton
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          rounded
          id="sign-in-button"
          onClick={handleOnContinueEmail}
          loading={loading}
          disabled={loading}
        >
          Continuar
        </BaseButton>
      </FlexBox>

      <LoginFooter
        loginType={loginType}
        setLoginType={setLoginType}
        saveUserInfo={saveUserInfo}
      />
    </StyledContainer>
  )
}
