import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { Colors } from '../../constants/theme'

export const StyledSectionTitle = styled.div`
  font-size: 32px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${Colors.black500};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    text-align: left;
  }
`

export const StyledSectionTitleColor = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const StyledLoginSection = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const StyledRecaptcha = styled.div`
  margin: 0 auto;
  display: table;
`

export const StyledSectionPreTitle = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${Colors.black400};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`
