import styled from '@emotion/styled'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import Link from 'next/link'
import React from 'react'
import { BorderRadius, Colors } from '../../constants/theme'

interface StyledBaseButtonProps {
  width?: number | string
  height?: number | string
  size?: string
  borderradius?: number
  fsize?: number | string
  fweight?: number | string
  ffamily?: string
  blueborder?: number
  whiteborder?: number
  border?: string
  customcolor?: string
  customcontrastcolor?: string
  rounded?: number
  shadow?: number
  underline?: number
  variant?: LoadingButtonProps['variant']
  component?: string
  nopadding?: number
}

const StyledBaseButton = styled(LoadingButton)<StyledBaseButtonProps>`
  && {
    && {
      box-shadow: none;
      text-transform: none;
      text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
      font-size: ${({ fsize }) =>
        typeof fsize === 'number' ? `${fsize}px` : fsize};
      padding: ${({ nopadding }) => (nopadding ? '0px' : '6px 18px')};
      border-radius: ${({ borderradius }) =>
        borderradius ? `${borderradius}px` : `${BorderRadius.normal}`};
      ${({ rounded }) => rounded && 'border-radius: 100px;'};
      font-weight: ${({ fweight }) => (fweight ? fweight : '500')};
      font-family: ${({ ffamily }) => ffamily};
      line-height: 19px;
      width: ${({ width }) =>
        typeof width === 'number' ? `${width}px` : width};
      height: ${({ size, height }) =>
        size === 'large'
          ? '50px'
          : height
            ? typeof height === 'number'
              ? `${height}px`
              : height
            : '40px'};

      ${({ customcolor, customcontrastcolor, variant }) => {
        if (variant === 'contained') {
          return `
          background-color: ${customcolor};
          color: ${customcontrastcolor || '#fff'};
        `
        }
        if (variant === 'outlined') {
          return `
          border: 1px solid ${customcolor};
          color: ${customcontrastcolor || customcolor};
        `
        }
        if (variant === 'text') {
          return `
        color: ${customcontrastcolor || customcolor};
        &:hover {
          background-color: ${customcontrastcolor}0A;
        }
        `
        }
        return `
        color: ${customcontrastcolor || customcolor};`
      }};

      ${({ blueborder }) =>
        blueborder &&
        `border: 3.5px solid ${Colors.buttonBorderBlue}; padding-top: 8px;`}
      ${({ whiteborder }) =>
        whiteborder &&
        `border: 3.5px solid ${Colors.grey400}; padding-top: 8px;`}
      ${({ border }) => border && `border: ${border};`}
      ${({ shadow }) =>
        shadow ? 'box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);' : ''}

      &:hover {
        box-shadow: none;
        filter: brightness(110%);
      }
    }
  }
`

export interface BaseButtonProps extends LoadingButtonProps {
  width?: number | string
  height?: number | string
  href?: string
  borderRadius?: number
  fontSize?: number
  fontWeight?: number | string
  fontFamily?: string
  bold?: boolean
  customColor?: string
  customContrastColor?: string
  rounded?: boolean
  shadow?: boolean
  underline?: boolean
  component?: string
  blueBorder?: boolean
  whiteBorder?: boolean
  border?: string
  newTab?: boolean
  prefetch?: boolean
  legacyBehavior?: boolean
  noPadding?: boolean
}

const BaseButton = (props: BaseButtonProps) => {
  const {
    rounded,
    width,
    height,
    href,
    size,
    borderRadius,
    bold,
    border,
    blueBorder,
    whiteBorder,
    customColor,
    fontSize = 16,
    fontWeight,
    fontFamily,
    customContrastColor,
    shadow,
    underline,
    sx,
    newTab,
    prefetch,
    legacyBehavior,
    noPadding,
    ...rest
  } = props
  const color =
    customColor === undefined && rest.variant === 'contained'
      ? rest.disabled || rest.loading
        ? Colors.grey400
        : Colors.primary
      : customColor

  if (href && newTab) {
    return (
      <StyledBaseButton
        width={width}
        height={height}
        size={size}
        borderradius={borderRadius}
        fsize={fontSize}
        fweight={bold ? 'bold' : fontWeight}
        ffamily={fontFamily}
        customcolor={customColor}
        blueborder={blueBorder ? 1 : 0}
        whiteborder={whiteBorder ? 1 : 0}
        border={border}
        customcontrastcolor={customContrastColor}
        rounded={rounded ? 1 : 0}
        shadow={shadow ? 1 : 0}
        underline={underline ? 1 : 0}
        nopadding={noPadding ? 1 : 0}
        sx={sx}
        // New Tab
        href={href}
        rel="noreferrer"
        {...{ target: '_blank' }}
        {...rest}
      />
    )
  }

  const button = (
    <StyledBaseButton
      width={width}
      height={height}
      size={size}
      borderradius={borderRadius}
      fsize={fontSize}
      fweight={bold ? 'bold' : fontWeight}
      ffamily={fontFamily}
      customcolor={color}
      blueborder={blueBorder ? 1 : 0}
      whiteborder={whiteBorder ? 1 : 0}
      border={border}
      customcontrastcolor={customContrastColor}
      rounded={rounded ? 1 : 0}
      shadow={shadow ? 1 : 0}
      underline={underline ? 1 : 0}
      nopadding={noPadding ? 1 : 0}
      sx={sx}
      {...rest}
    />
  )

  return href ? (
    <Link
      href={href}
      passHref
      prefetch={prefetch}
      legacyBehavior={legacyBehavior || rest.fullWidth}
    >
      {button}
    </Link>
  ) : (
    button
  )
}

export default React.memo(BaseButton)
