import { useState } from 'react'
import { IRegisterUserInput } from 'src/types/graphql'
import { IEmailState, LoginType, LoginTypeEvent } from '../common'
import ContinueEmail from './ContinueEmail'
import LoginEmailPrompt from './LoginEmailPrompt'
import RegisterEmail from './RegisterEmail'

type IProps = {
  isModal: boolean
  loginType: LoginType
  setLoginType: (type: LoginType) => void
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export default function LoginEmail({
  isModal,
  loginType,
  setLoginType,
  saveUserInfo
}: IProps) {
  const [{ email, emailExists }, setEmailState] = useState<IEmailState>({})

  if (email) {
    return emailExists ? (
      <ContinueEmail email={email} setEmailState={setEmailState} />
    ) : (
      <RegisterEmail
        email={email}
        setEmailState={setEmailState}
        saveUserInfo={saveUserInfo}
      />
    )
  }

  return (
    <LoginEmailPrompt
      isModal={isModal}
      loginType={loginType}
      setLoginType={setLoginType}
      initialEmail={email}
      setEmailState={setEmailState}
      saveUserInfo={saveUserInfo}
    />
  )
}
