import EmailIcon from '@mui/icons-material/Email'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import Divider from '@mui/material/Divider'
import Image from 'next/image'
import { IRegisterUserInput } from 'src/types/graphql'
import { Colors, FontSize, Spacing } from '../../constants/theme'
import BaseButton from '../Buttons/BaseButton'
import FlexBox from '../FlexBox'
import SocialLogin from './SocialLogin'
import {
  StyledSectionPreTitle,
  StyledSectionTitle,
  StyledSectionTitleColor
} from './styled'

export function LoginHeader({ isModal }: { isModal: boolean }) {
  return (
    <FlexBox fullWidth direction="column" mb={1}>
      {!isModal && (
        <StyledSectionPreTitle>
          Inicia sesión o registrate
        </StyledSectionPreTitle>
      )}
      <StyledSectionTitle>
        Bienvenido a <StyledSectionTitleColor>SpotMe</StyledSectionTitleColor>
      </StyledSectionTitle>
    </FlexBox>
  )
}

export type LoginTypeEvent = 'phone' | 'email' | 'google' | 'facebook'
export type LoginType = 'phone' | 'email'

type ILoginFooterProps = {
  loginType: LoginType
  setLoginType: (type: LoginType) => void
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export function LoginFooter({
  loginType,
  setLoginType,
  saveUserInfo
}: ILoginFooterProps) {
  const { Icon, text } =
    loginType === 'email'
      ? {
          Icon: SmartphoneIcon,
          text: 'Continuar con Teléfono'
        }
      : {
          Icon: EmailIcon,
          text: 'Continuar con Correo'
        }

  return (
    <>
      <FlexBox
        fullWidth
        direction="column"
        py={Spacing.small}
        color={Colors.black500}
      >
        <Divider>o</Divider>
      </FlexBox>

      <SocialLogin saveUserInfo={saveUserInfo} />

      <BaseButton
        fullWidth
        variant="outlined"
        rounded
        size="large"
        fontSize={FontSize.small}
        customColor={Colors.black300}
        customContrastColor={Colors.black400}
        onClick={() => setLoginType(loginType === 'email' ? 'phone' : 'email')}
      >
        <Icon sx={{ mr: 1 }} style={{ color: Colors.black400 }} />
        {text}
      </BaseButton>
    </>
  )
}

export type IEmailState = {
  email?: string
  emailExists?: boolean
}

type IBackButtonProps = {
  onClick: () => void
  message: string
}

export function BackButton({ onClick, message }: IBackButtonProps) {
  return (
    <BaseButton fullWidth variant="text" size="small" onClick={onClick}>
      <Image
        src="/svgs/back-arrow.svg"
        alt="back-arrow"
        width={15}
        height={15}
      />
      {message}
    </BaseButton>
  )
}
