import { gql, useMutation } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { USER_FRAGMENT } from 'src/constants/fragments'
import useLogger from 'src/context/LoggerProvider/useLogger'
import fbp from 'src/utils/pixels/fbp'
import ga from 'src/utils/pixels/gtag'
import { useAuthProvider } from '../../context/AuthProvider'
import {
  IMutation,
  IMutationRegisterUserArgs,
  IRegisterUserInput
} from '../../types/graphql'
import AlreadyLoggedIn from './AlreadyLoggedIn'
import LoginEmail from './LoginEmail'
import LoginPhone from './LoginPhone'
import { LoginType, LoginTypeEvent } from './common'

const REGISTER__USER = gql`
  ${USER_FRAGMENT}
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        ...CoreUserFields
      }
    }
  }
`

type IProps = {
  isModal?: boolean
}

function Login({ isModal }: IProps) {
  const logger = useLogger()
  const { isAuthenticated, referrer } = useAuthProvider()
  const [loginType, setLoginType] = useState<LoginType>('phone')

  const [registerUser] = useMutation<
    Pick<IMutation, 'registerUser'>,
    IMutationRegisterUserArgs
  >(REGISTER__USER, {
    onError: (error) => {
      logger.error('Error registering user.', error)
    }
  })

  const saveUserInfo = useCallback(
    async (input: IRegisterUserInput, method: LoginTypeEvent) => {
      await registerUser({ variables: { input: { referrer, ...input } } })
      ga.event('sign_up', { method })
      fbp.event('CompleteRegistration')
    },
    [registerUser, referrer]
  )

  if (isAuthenticated) {
    return <AlreadyLoggedIn />
  }

  return loginType === 'phone' ? (
    <LoginPhone
      isModal={!!isModal}
      loginType={loginType}
      setLoginType={setLoginType}
      saveUserInfo={saveUserInfo}
    />
  ) : (
    <LoginEmail
      isModal={!!isModal}
      loginType={loginType}
      setLoginType={setLoginType}
      saveUserInfo={saveUserInfo}
    />
  )
}

export default React.memo(Login)
