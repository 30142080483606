import { rubik } from './fonts'

export const Colors = {
  primary: '#00A4FF',
  primaryMediumLight: '#00A4FF',
  primaryLight: '#E6F6FF',
  primaryBold: '#30465F',
  primaryMediumDark: '#0094EE',
  primaryDark: '#0084D7',
  text: '#404040',
  link: '#47A1F7',
  darkBlue: '#013C80',
  linkLight: '#E4EDF6',
  white: '#FFFFFF',
  bluishWhite: '#E5EEF2',
  lightestGray: '#F7F7F7',
  lighterGray: '#DBDBDB',
  lightGray: '#B1B1B1',
  gray: '#636363',
  omgThisGray: '#929292',
  darkGray: '#404040',
  titleGray: '#A0B3BE',
  subtitleGray: '#343A40',
  borderLigthGray: '#C4C4C4',
  borderGray: 'rgba(0, 0, 0, 0.12)',
  buttonBorderGray: '#545454',
  buttonBorderBlue: '#3DB5FE',
  faintGray: '#686868',
  black: '#000000',
  yellowStar: '#F9BA48',
  red: '#FF0000',
  neutral: '#6C757D',
  softGray: '#EEF8FD',
  // New color pallete
  green500: '#5BE793',
  green400: '#84F0AF',
  yellow500: '#FCCA4A',
  yellow400: '#FFD466',
  red500: '#EE5568',
  red400: '#FA7E8D',
  blue500: '#00A4FF',
  blue400: '#00A4FFB2',
  blue300: '#D8F1FF',
  grey500: '#C7DEEB',
  grey400: '#D1E9F4',
  grey300: '#EEF8FD',
  grey200: '#EEF8FD66',
  grey100: '#EEF8FD4D',
  black500: '#30465F',
  black400: '#465B75',
  black300: '#465B7566',
  brown500: '#636363',
  white500: '#FFFFFF',
  white400: '#FFFFFF33',
  white300: '#FFFFFF1A',
  gradient500:
    'linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(200,205,249,1) 60%, rgba(227,229,251,1) 100%)',
  gradient400:
    'linear-gradient(180deg, rgba(53,61,89,0.2) 0%, rgba(53,61,89,1) 100%)',
  gradient300:
    'linear-gradient(180deg, rgba(238,248,253,1) 15%, rgba(238,248,253,0.65) 60%, rgba(238,248,253,1) 100%)',
  gradient200:
    'linear-gradient(180deg, rgba(39,39,39,1) 0%, rgba(39,39,39,0) 100%)'
}

export const Spacing = {
  none: 0,
  xSmall: 0.5,
  small: 1,
  medium: 2,
  large: 3,
  xlarge: 4,
  navbar: 9
}

export const BorderRadius = {
  none: 0,
  normal: 4,
  medium: 8,
  large: 10,
  xLarge: 15,
  xxLarge: 20,
  ultra: 25,
  xultra: 30,
  xxultra: 35,
  full: 100
}

export const FontSize = {
  smallest: 12,
  small: 14,
  normal: 16,
  large: 18
}

export const IconSize = {
  smallest: 12,
  normal: 16,
  big: 20
}

export const FontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
}

export const FontFamily = {
  Rubik: rubik.style.fontFamily
}

export const globalStyles = {
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  html: {
    height: '100%',
    strong: {
      color: Colors.primary
    },
    fontFamily: rubik.style.fontFamily
  },
  body: {
    color: Colors.text,
    height: '100%',
    '*::-webkit-scrollbar': {
      width: '4px'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: '100px'
    }
  },
  '#__next': {
    height: '100%'
  }
}
