import Typography from '@mui/material/Typography'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import useScrollToTop from 'src/hooks/useScrollToTop'
import { Colors, FontSize, FontWeight, Spacing } from '../../constants/theme'
import BaseButton from '../Buttons/BaseButton'
import BaseIconButton from '../Buttons/BaseIconButton'
import FlexBox from '../FlexBox'
import PhoneCodeInput from './CodeInput'
import { StyledContainer } from './styled'

type IConfirmProps = {
  phoneNumber: string
  handleOnConfirmCode: (code: string) => void
  returnPhone: () => void
}

export default function LoginPhoneConfirm({
  phoneNumber,
  handleOnConfirmCode,
  returnPhone
}: IConfirmProps) {
  const [code, setCode] = useState<string>('')
  const scrollToTop = useScrollToTop()

  useEffect(() => {
    if (code.length === 6) {
      handleOnConfirmCode(code)
    }
  }, [code, handleOnConfirmCode])

  useEffect(() => {
    scrollToTop()
  }, [scrollToTop])

  return (
    <StyledContainer>
      <FlexBox alignCenter gap={1}>
        <BaseIconButton onClick={() => returnPhone()}>
          <Image
            src="/svgs/back-arrow.svg"
            alt="back-arrow"
            width={15}
            height={15}
            quality={100}
          />
        </BaseIconButton>
        <Typography color={Colors.black500} fontWeight={FontWeight.bold}>
          Confirma tu número teléfonico
        </Typography>
      </FlexBox>
      <FlexBox my={2}>
        <Typography color={Colors.black400}>
          Ingresa el código que fue enviado via SMS al siguiente número{' '}
          <b>{phoneNumber}</b>
        </Typography>
      </FlexBox>
      <FlexBox my={2}>
        <PhoneCodeInput onComplete={(code) => setCode(code)} />
      </FlexBox>
      <FlexBox fullWidth mb={Spacing.small} mt={0.5} spaceBetween>
        <Typography fontSize={FontSize.small} color={Colors.black400}>
          ¿No recibiste el código?{' '}
          <Typography
            component="span"
            fontSize={FontSize.small}
            style={{ textDecoration: 'underline' }}
            fontWeight={FontWeight.medium}
            onClick={() => returnPhone()}
            sx={{ cursor: 'pointer' }}
          >
            Reenviar
          </Typography>
        </Typography>

        <Typography
          component="span"
          fontSize={FontSize.small}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          fontWeight={FontWeight.medium}
          color={Colors.black400}
          onClick={() => returnPhone()}
        >
          Más opciones
        </Typography>
      </FlexBox>

      <FlexBox fullWidth direction="column" py={Spacing.xSmall} center>
        <BaseButton
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleOnConfirmCode(code)}
          disabled={!code}
          rounded
        >
          Iniciar sesión
        </BaseButton>
      </FlexBox>
    </StyledContainer>
  )
}
