/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import Box, { BoxProps } from '@mui/material/Box'
import React from 'react'

type IStyledFlexBoxProps = BoxProps & {
  justifystart?: number
  justifyend?: number
  justifycenter?: number
  spacebetween?: number
  alignstart?: number
  alignend?: number
  aligncenter?: number
  center?: number
  direction?: string
  column?: number
  columnmd?: number
  columnddownmd?: number
  fullwidth?: number
  fullscreen?: number
  scrollable?: number
  absolute?: number
  fixed?: number
  bgcolor?: string | any
  radius?: number
  ssx?: any
}

const StyledFlexBox = styled(Box)<IStyledFlexBoxProps>`
  && {
    position: relative;
    display: flex;
    ${({ justifystart }) =>
      justifystart ? 'justify-content: flex-start;' : ''};
    ${({ justifyend }) => (justifyend ? 'justify-content: flex-end;' : '')};
    ${({ justifycenter }) => (justifycenter ? 'justify-content: center;' : '')};
    ${({ spacebetween }) =>
      spacebetween ? 'justify-content: space-between;' : ''};
    ${({ alignstart }) => (alignstart ? 'align-items: flex-start;' : '')};
    ${({ alignend }) => (alignend ? 'align-items: flex-end;' : '')};
    ${({ aligncenter }) => (aligncenter ? 'align-items: center;' : '')};
    ${({ center }) =>
      center ? 'justify-content: center; align-items: center;' : ''};
    ${({ direction }) => (direction ? `flex-direction: ${direction}` : '')};
    ${({ column }) => (column ? 'flex-direction: column' : '')};
    ${({ fullwidth }) => (fullwidth ? 'width: 100%;' : '')};
    ${({ fullscreen }) => (fullscreen ? 'width: 100%; height: 100%;' : '')};
    ${({ scrollable }) => (scrollable ? 'overflow-y: scroll;' : '')};
    ${({ absolute }) => (absolute ? 'position: absolute;' : '')};
    ${({ fixed }) => (fixed ? 'position: fixed;' : '')};
    ${({ bgcolor }) => (bgcolor ? `background-color: ${bgcolor};` : '')};
    ${({ radius }) => (radius ? `border-radius: ${radius}px;` : '')};
    ${({ ssx }) => ssx};
    ${({ theme }) => theme.breakpoints.up('md')} {
      ${({ columnmd }) => (columnmd ? 'flex-direction: column' : '')};
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      ${({ columnddownmd }) => (columnddownmd ? 'flex-direction: column' : '')};
    }
  }
`

export interface FlexBoxProps extends BoxProps {
  children: any
  center?: boolean
  justifyCenter?: boolean
  justifyStart?: boolean
  justifyEnd?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  spaceBetween?: boolean
  direction?: 'row' | 'column' | string
  column?: boolean
  columnMd?: boolean
  columnDownMd?: boolean
  fullWidth?: boolean
  fullScreen?: boolean
  scrollable?: boolean
  absolute?: boolean
  fixed?: boolean
  bgColor?: string
  radius?: number
  styles?: any
  sx?: any
  justifyRight?: boolean
  justifyLeft?: boolean
  alignLeft?: boolean
  alignRight?: boolean
}

const FlexBox = ({
  children,
  center,
  spaceBetween,
  justifyCenter,
  justifyStart,
  justifyEnd,
  alignStart,
  alignEnd,
  alignCenter,
  direction,
  column,
  columnMd,
  columnDownMd,
  fullWidth,
  fullScreen,
  scrollable,
  absolute,
  fixed,
  bgColor,
  radius,
  styles,
  sx,
  ...rest
}: FlexBoxProps) => (
  <StyledFlexBox
    style={styles}
    center={center ? 1 : 0}
    spacebetween={spaceBetween ? 1 : 0}
    justifycenter={justifyCenter ? 1 : 0}
    justifystart={justifyStart ? 1 : 0}
    justifyend={justifyEnd ? 1 : 0}
    alignstart={alignStart ? 1 : 0}
    alignend={alignEnd ? 1 : 0}
    aligncenter={alignCenter ? 1 : 0}
    direction={direction}
    column={column ? 1 : 0}
    columnmd={columnMd ? 1 : 0}
    columnddownmd={columnDownMd ? 1 : 0}
    fullwidth={fullWidth ? 1 : 0}
    fullscreen={fullScreen ? 1 : 0}
    scrollable={scrollable ? 1 : 0}
    absolute={absolute ? 1 : 0}
    fixed={fixed ? 1 : 0}
    bgcolor={bgColor}
    radius={radius}
    ssx={sx}
    sx={sx}
    {...rest}
  >
    {children}
  </StyledFlexBox>
)

export default React.memo(FlexBox)
