import styled from '@emotion/styled'
import { ButtonProps } from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Colors } from '../../constants/theme'

interface StyledBaseIconButtonProps {
  width?: number | string
  size?: string
  customcolor?: string
  customcontrastcolor?: string
  disabledstyle?: number
  variant?: ButtonProps['variant']
}

export const StyledBaseIconButton = styled(
  IconButton
)<StyledBaseIconButtonProps>`
  && {
    font-size: 16px;

    ${({ customcolor, customcontrastcolor, variant, disabledstyle }) => {
      if (variant === 'contained') {
        if (disabledstyle)
          return `
          &.Mui-disabled {
            background-color: ${Colors.lighterGray};
            svg { color: '#fff'; };
          }
        `
        return `
          background-color: ${customcolor || Colors.lighterGray};
          svg { color: ${customcontrastcolor || '#fff'} };

          &:hover {
            background-color: ${customcolor || Colors.lighterGray};
          }
        `
      }
      if (variant === 'outlined') {
        if (disabledstyle)
          return `
          border: 1px solid ${Colors.lighterGray};
          color: ${Colors.lighterGray};
        `
        return `
          border: 1px solid ${customcolor || Colors.lighterGray};
          color: ${customcontrastcolor || customcolor || Colors.lighterGray};
          &:hover {
            background-color: transparent;
          }
        `
      }
      if (disabledstyle) return `color: ${Colors.lighterGray}; cursor: pointer`
      return `color: ${customcontrastcolor || customcolor};`
    }};

    &:hover {
      box-shadow: none;
      filter: brightness(120%);
    }
  }
`

interface BaseIconButtonProps extends ButtonProps {
  width?: number | string
  disabled?: boolean
  customColor?: string
  customContrastColor?: string
}

export const BaseIconButton = (props: BaseIconButtonProps) => {
  const { width, disabled, customColor, customContrastColor, size, ...rest } =
    props

  return (
    <StyledBaseIconButton
      disabledstyle={disabled ? 1 : 0}
      width={width}
      size={size}
      customcolor={customColor}
      customcontrastcolor={customContrastColor}
      {...rest}
    />
  )
}

export default BaseIconButton
