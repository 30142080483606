import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import firebase from 'firebase/compat/app'
import Image from 'next/image'
import Link from 'next/link'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { IRegisterUserInput } from 'src/types/graphql'
import { Colors, FontSize, FontWeight, Spacing } from '../../../constants/theme'
import { RedirectUrls } from '../../../types/types'
import BaseButton from '../../Buttons/BaseButton'
import BaseIconButton from '../../Buttons/BaseIconButton'
import FlexBox from '../../FlexBox'
import PasswordInput from '../PasswordInput'
import { IEmailState, LoginTypeEvent } from '../common'
import { StyledContainer } from '../styled'

type IProps = {
  email: string
  setEmailState: (state: IEmailState) => void
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export default function RegisterEmail({
  email,
  setEmailState,
  saveUserInfo
}: IProps) {
  const logger = useLogger()

  const [loading, setLoading] = useState<boolean>(false)
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { enqueueSnackbar } = useSnackbar()

  const handleOnRegister = () => {
    setLoading(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async () => {
        await saveUserInfo({ firstname, lastname, email }, 'email')
      })
      .catch((error) => {
        enqueueSnackbar('¡Hubo un error! Por favor intenta de nuevo.', {
          variant: 'error'
        })
        if (error.code !== 'auth/email-already-in-use') {
          logger.error('Error email/password login', error)
        }
        setLoading(false)
      })
  }

  return (
    <StyledContainer>
      <FlexBox alignCenter gap={1}>
        <BaseIconButton onClick={() => setEmailState({})}>
          <Image
            src="/svgs/back-arrow.svg"
            alt="back-arrow"
            width={15}
            height={15}
            quality={100}
          />
        </BaseIconButton>
        <Typography color={Colors.black500} fontWeight={FontWeight.bold}>
          Termina tu registro
        </Typography>
      </FlexBox>

      <FlexBox fullWidth direction="column" pt={Spacing.medium}>
        <Box my={1}>
          <TextField
            id="firstname"
            name="firstname"
            label="Nombre(s)"
            type="text"
            value={firstname}
            onChange={(event) => setFirstname(event.target.value)}
            size="medium"
            fullWidth
          />
        </Box>
        <Box my={1}>
          <TextField
            id="lastname"
            name="lastname"
            label="Apellido(s)"
            type="text"
            value={lastname}
            onChange={(event) => setLastname(event.target.value)}
            size="medium"
            fullWidth
          />
        </Box>
        <Box my={1}>
          <TextField
            required
            id="email"
            name="email"
            label="Correo"
            fullWidth
            autoComplete="email"
            value={email}
            disabled
            size="medium"
          />
        </Box>

        <Box mb={1}>
          <PasswordInput
            password={password}
            setPassword={setPassword}
            hideTooltip
          />
        </Box>
      </FlexBox>

      <FlexBox fullWidth center py={Spacing.small}>
        <Typography fontSize={FontSize.smallest}>
          Al seleccionar Aceptar y continuar, acepto los{' '}
          <Link href={RedirectUrls.TermsAndConditions} passHref>
            <Typography
              component="span"
              fontSize={FontSize.smallest}
              style={{ textDecoration: 'underline' }}
              fontWeight={FontWeight.medium}
            >
              Términos del servicio
            </Typography>
          </Link>
          , así como su{' '}
          <Link href={RedirectUrls.Disclosures} passHref>
            <Typography
              component="span"
              fontSize={FontSize.smallest}
              style={{ textDecoration: 'underline' }}
              fontWeight={FontWeight.medium}
            >
              Política de privacidad.
            </Typography>
          </Link>
        </Typography>
      </FlexBox>

      <FlexBox fullWidth direction="column" py={Spacing.xSmall} center>
        <BaseButton
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={handleOnRegister}
          disabled={!password}
          rounded
          loading={loading}
        >
          Aceptar y continuar
        </BaseButton>
      </FlexBox>
    </StyledContainer>
  )
}
