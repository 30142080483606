import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import { useState } from 'react'
import { useAuthProvider } from 'src/context/AuthProvider'
import { AuthEmailType } from 'src/types/graphql'
import { RedirectUrls } from 'src/types/types'
import { Colors, FontWeight, Spacing } from '../../../constants/theme'
import { validEmail } from '../../../utils'
import BaseButton from '../../Buttons/BaseButton'
import BaseIconButton from '../../Buttons/BaseIconButton'
import FlexBox from '../../FlexBox'
import { StyledContainer } from '../styled'

type IEmailSentProps = {
  email: string
  setForgotPasswordScreen: (screen: boolean) => void
}

const EmailSent = ({ email, setForgotPasswordScreen }: IEmailSentProps) => (
  <StyledContainer>
    <FlexBox fullWidth direction="column" center>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h4">
        Se envio un correo a {email} para log in.
      </Typography>
      <Typography variant="h5">
        Si no lo encuntras, porfavor revisa tu bandeja de entrada no deseada.
      </Typography>
      <BaseButton
        fullWidth
        variant="text"
        color="primary"
        size="small"
        onClick={() => setForgotPasswordScreen(false)}
      >
        Regresar
      </BaseButton>
    </FlexBox>
  </StyledContainer>
)

type IProps = {
  initialEmail: string
  setForgotPasswordScreen: (screen: boolean) => void
}

export default function LoginEmail({
  initialEmail,
  setForgotPasswordScreen
}: IProps) {
  const { sendAuthEmail } = useAuthProvider()

  const [email, setEmail] = useState<string>(initialEmail)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleForgotPassword = async () => {
    setLoading(true)
    await sendAuthEmail(email, AuthEmailType.ResetPassword, RedirectUrls.Home)
    setEmailSent(true)
  }

  if (emailSent) {
    return (
      <EmailSent
        email={email}
        setForgotPasswordScreen={setForgotPasswordScreen}
      />
    )
  }

  return (
    <StyledContainer>
      <FlexBox alignCenter gap={1}>
        <BaseIconButton onClick={() => setForgotPasswordScreen(false)}>
          <Image
            src="/svgs/back-arrow.svg"
            alt="back-arrow"
            width={15}
            height={15}
            quality={100}
          />
        </BaseIconButton>
        <Typography fontWeight={FontWeight.bold} color={Colors.black500}>
          ¿Olvidaste tu contraseña?
        </Typography>
      </FlexBox>
      <FlexBox mt={1}>
        <Typography color={Colors.black400}>
          Ingresa el correo electronico asociado con tu cuenta, y nosotros
          enviaremos un correo para que puedas sustituirla.
        </Typography>
      </FlexBox>

      <FlexBox fullWidth direction="column" pt={Spacing.large}>
        <TextField
          required
          id="email"
          name="email"
          label="Correo"
          fullWidth
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          size="medium"
        />
      </FlexBox>

      <FlexBox fullWidth direction="column" pt={2} center>
        <BaseButton
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          disabled={!validEmail(email)}
          onClick={handleForgotPassword}
          loading={loading}
          rounded
        >
          Enviar Correo
        </BaseButton>
      </FlexBox>
    </StyledContainer>
  )
}
